<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
          <div class="col-lg-12"> -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">Gallery</h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::Item-->
        <div class="mb-12">
          <!--begin::Content-->
          <button
            type="button"
            class="btn btn-primary mb-2"
            size="sm"
            style="background-color: #030a8c; border-color: #030a8c"
            v-b-modal.modal-tambah
          >
            <i class="flaticon2-plus"></i>Tambah
          </button>
          <div>
            <div style="overflow-x: auto; overflow-y: hidden">
              <table
                id="example"
                class="table table-striped table-bordered"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th style="width: 350px">Gambar/Video</th>
                    <th style="width: 350px">Judul</th>
                    <th style="width: 350px">Caption</th>
                    <th>Publish</th>
                    <th>Tahun</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in kegiatan" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <a
                        v-if="row.tipe === 'video'"
                        :href="'https://www.youtube.com/embed/' + row.url_file"
                        target="blank"
                      >
                        <b-img
                          :src="
                            'https://img.youtube.com/vi/' +
                            row.url_file +
                            '/default.jpg'
                          "
                          style="width: 100px"
                          fluid
                          alt="Foto"
                        ></b-img>
                      </a>
                      <a v-else :href="row.url_file" target="blank">
                        <b-img
                          :src="row.url_file"
                          style="width: 100px"
                          fluid
                          alt="Foto"
                        ></b-img>
                      </a>
                    </td>
                    <td>{{ row.title }}</td>
                    <td>{{ shortedCaption(row.caption) }}</td>
                    <td>{{ row.publis }}</td>
                    <td>{{ row.tahun }}</td>
                    <td>
                      <div style="width: 150px">
                        <b-button
                          data-toggle="modal"
                          data-target="#editgallery"
                          variant="success"
                          size="sm"
                          style="backgorund-color: #66a626"
                          @click="getperfaq(row.id)"
                        >
                          <i class="flaticon-edit"></i>Ubah
                        </b-button>
                        &nbsp;
                        <b-button
                          @click="deleteData(row.id)"
                          size="sm"
                          variant="danger"
                          ><i class="flaticon2-trash"></i> Hapus</b-button
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Item-->
      </div>
      <!--end: Card Body-->
    </div>

    <!-- Tambah -->
    <b-modal
      v-model="show"
      id="modal-tambah"
      ref="modal"
      size="xl"
      title="Tambah Gallery"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="tambahgallery">
        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Title</label>
          <div class="col-lg-10">
            <b-form-input v-model="title" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Caption</label>
          <div class="col-lg-10">
            <vue-editor v-model="caption" />
            <!-- <b-textarea rows="5" v-model="caption" class="form-control" /> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2">Tipe</label>
          <div class="col-10">
            <b-form-select
              v-model="selectedTipe"
              :options="tipe"
            ></b-form-select>
          </div>
        </div>
        <div class="form-group row" v-if="selectedTipe == 'gambar'">
          <label class="col-2">Gambar</label>
          <div class="col-10">
            <b-form-file
              accept="image/*"
              v-model="gambar"
              type="file"
              ref="file"
            ></b-form-file>
          </div>
        </div>
        <div class="form-group row" v-else>
          <label class="col-2">ID Video Youtube</label>
          <div class="col-10">
            <b-form-input v-model="gambar" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2">Publish</label>
          <div class="col-10">
            <b-form-select
              v-model="selectedpublish"
              :options="publish"
            ></b-form-select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2">Tahun</label>
          <div class="col-10">
            <b-form-select
              v-model="selectedTahun"
              :options="tahun"
            ></b-form-select>
          </div>
        </div>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <!-- <p class="float-left">Modal Footer Content</p> -->
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="
              background-color: #030a8c;
              border-color: #030a8c;
              color: white;
            "
            @click="tambahgallery"
          >
            Kirim
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="margin-right: 10px"
            @click="show = false"
          >
            Tutup
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- modal edit kegiatan -->
    <div
      class="modal fade"
      id="editgallery"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Ubah Data</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
          <label class="col-lg-2 col-form-label">Title</label>
          <div class="col-lg-10">
            <b-form-input v-model="getdetail.title" class="form-control" />
          </div>
        </div>
            <div class="form-group row">
              <label class="col-lg-2 col-form-label">Caption</label>
              <div class="col-lg-10">
                <vue-editor v-model="getdetail.caption" />
                <!-- <b-textarea
                  rows="5"
                  v-model="getdetail.caption"
                  class="form-control"
                /> -->
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2">Tipe</label>
              <div class="col-10">
                <b-form-select
                  v-model="getdetail.tipe"
                  :options="tipe"
                ></b-form-select>
              </div>
            </div>
            <div class="form-group row" v-if="getdetail.tipe == 'gambar'">
              <label class="col-2">Gambar</label>
              <div class="col-10">
                <b-form-file
                  accept="image/*"
                  v-model="getdetail.url_file"
                  type="file"
                  ref="file"
                ></b-form-file>
                <span class="text-info">*Tidak perlu mengunggah ulang gambar jika tidak diubah!</span>
              </div>
            </div>
            <div class="form-group row" v-else>
              <label class="col-2">ID Video Youtube</label>
              <div class="col-10">
                <b-form-input v-model="getdetail.url_file" class="form-control" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-2">Publish</label>
              <div class="col-10">
                <b-form-select
                  v-model="getdetail.publis"
                  :options="publish"
                ></b-form-select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-2">Tahun</label>
              <div class="col-10">
                <b-form-select
                  v-model="getdetail.tahun"
                  :options="tahun"
                ></b-form-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              @click="editgallery(getdetail.id)"
              style="
                background-color: #030a8c;
                border-color: #030a8c;
                color: white;
              "
              data-dismiss="modal"
              class="btn btn-primary"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
    </div> -->
</template>
  <style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
  <script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import JsonExcel from "vue-json-excel";
import { VueEditor } from "vue2-editor";

Vue.component("downloadExcel", JsonExcel);
export default {
  name: "dashboard",
  components: { VueEditor },
  data() {
    return {
      url: localStorage.getItem("cms"),
      token: localStorage.getItem("id_token"),
      show: false,
      test: "hai",
      kegiatan: [],
      search: "",
      gambar: "",
      pertanyaan: "",
      caption: "",
      jawabantipe: null,
      selectedpublish: true,
      selectedTahun: null,
      selectedTipe: null,
      publish: [
        { value: true, text: "Ya" },
        { value: false, text: "Tidak" },
      ],
      tahun: [],
      tipe: [
        { value: "video", text: "Video" },
        { value: "gambar", text: "Gambar" },
      ],
      getdetail: [],
      json_fields: {
        Agenda: "agenda",
        Tahun: "tahun",
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Gallery" }]);
    this.getdata();
    this.rangeTahun();
  },
  methods: {
    rangeTahun() {
      //this.selectedTahunKode = current;
      var currentYear = new Date().getFullYear() + 5;

      console.log(currentYear);
      var startYear = new Date().getFullYear() - 5;
      while (startYear <= currentYear) {
        this.tahun.push(startYear++);
      }
    },
    isValidUrl(urlString) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    },
    shortedCaption(value) {
            if (value.length > 100) {
                return value.slice(0, 100) + "...";
            }

            return value;
        },
    getdata() {
      $("#example").DataTable().destroy();
      this.kegiatan = [];
      this.loaddata();
    },
    loaddata() {
      axios
        .get(this.url + "/galeri", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.kegiatan = response.data.data;
          this.initDatatable();
        })
        .catch((error) => {
          console.log(error);
          return error;
          // this.loaddata()
        });
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },
    resetModal() {
      this.agenda = "";
      this.status_sistem = "Buka";
      this.tanggal_mulai = "";
      this.tanggal_selesai = "";
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.tambahgallery();
    },
    tambahgallery() {
      let formData = new FormData();
      if (this.selectedTipe == "video") {
        formData.append("url_file", this.gambar);
      } else {
        formData.append("file", this.gambar);
      }

      formData.append("tipe", this.selectedTipe);
      formData.append("title", this.title);
      formData.append("caption", this.caption);
      formData.append("publis", this.selectedpublish);
      formData.append("tahun", this.selectedTahun);
      axios
        .post(this.url + "/galeri", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            xth: this.token,
          },
        })
        .then((res) => {
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          console.log(res);
          this.getdata();
          // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Gagal Disimpan", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });

      this.$nextTick(() => {
        this.$bvModal.hide("modal-tambah");
      });
    },
    getperfaq(id) {
      axios
        .get(this.url + `/galeri?id=${id}`, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          // axios.get(`http://penkin-penilaian.mysurvey.id/sdm/?filter=idpm,=,`+this.idpm+`;bagian,=,Kepala Dinas`).then(response => {
          this.getdetail = response.data.data;
          console.log(response.data.data);
          console.log("getdetail");
        });
    },
    handleEdit(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.editsdm();
    },
    editgallery(id) {
      let formData = new FormData();
      if (this.selectedTipe == "video") {
        formData.append("url_file", this.url_file);
      } else {
        if (this.isValidUrl(this.getdetail.url_file) == false) {
          formData.append("file", this.getdetail.url_file);
        }
      }
      // if (this.isValidUrl(this.getdetail.url_file) == false) {
      //   formData.append("url_file", this.getdetail.url_file);
      // }

      formData.append("title", this.getdetail.title);
      formData.append("caption", this.getdetail.caption);
      formData.append("publis", this.getdetail.publis);
      formData.append("tipe", this.getdetail.tipe);
      formData.append("tahun", this.getdetail.tahun);
      axios
        .post(this.url + `/galeri/${id}`, formData, {
          headers: {
            xth: this.token,
          },
        })
        .then((res) => {
          this.$bvToast.toast("Berhasil Diedit", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          console.log(res);
          this.getdata();
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Gagal Diedit", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
      // Push the name to submitted names
      // this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    deleteData(id) {
      axios
        .delete(this.url + `/galeri/${id}`, {
          headers: {
            "Content-type": "application/json",
            xth: this.token,
          },
        })
        .then((res) => {
          this.$bvToast.toast("Berhasil Dihapus", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          console.log(res);
          this.getdata();
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Gagal Dihapus", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  created() {},
};
</script>
  